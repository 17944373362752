/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Loading from "../Loading";
import { injected } from "../../utils/connectors";
import { switchChain } from "../../utils/switchChain";
import { getContractProvider } from "../../utils/getContractProvider";
import config from "../../config";
import MintWidget from "./MintWidget";
import allowlist from "../../utils/allowlist";
import "../../index.css";

const contractAbi = require("../../contracts/abi.abi.json");

const MintTemplate = () => {
  const { account, activate, deactivate, active, library } = useWeb3React();
  const [contract, setContract] = useState();
  const [userAddress, setUserAddress] = useState(null);
  const [salePrice, setSalePrice] = useState(BigNumber.from(0));
  const [balance, setBalance] = useState(BigNumber.from(0));
  const [plFree, setPlFree] = useState(0);
  const [alFree, setAlFree] = useState(0);
  const [maxTx, setMaxTx] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [freeMinted, setFreeMinted] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [isAllowlist, setIsAllowlist] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    const init = async () => {
      setContract(await getContractProvider());
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
  }, [account]);

  useEffect(() => {
    if (userAddress) {
      const refresh = async () => {
        await refreshContractStateHasAddress();
      };
      refresh();
    }
  }, [userAddress]);

  useEffect(() => {
    if (library) {
      setContract(
        new ethers.Contract(
          process.env.REACT_APP_ADDRESS,
          contractAbi,
          library.getSigner()
        )
      );
    }
  }, [library]);

  useEffect(() => {
    if (contract) {
      const init = async () => {
        setLoading(true);
        await refreshContractState();
        setLoading(false);
      };
      init();
    }
  }, [contract]);

  const refreshContractState = async () => {
    setSalePrice(await contract.salePrice());
    setPlFree((await contract.plFree()).toNumber());
    setAlFree((await contract.alFree()).toNumber());
    setMaxTx((await contract.maxTx()).toNumber());
    setMaxSupply((await contract.maxSupply()).toNumber());
    setIsPaused(await contract.paused());
    setTotalSupply((await contract.totalSupply()).toNumber());
    setFreeMinted((await contract.FREE_MINTED()).toNumber());
    console.log(allowlist.getMerkleTree().getHexRoot());
  };

  const refreshContractStateHasAddress = async () => {
    setBalance(await contract.balanceOf(userAddress));
    setIsAllowlist(allowlist.contains(userAddress));
    setClaimed(await contract.CLAIMED(userAddress));
  };

  const refreshStateAfterMint = async () => {
    setIsPaused(await contract.paused());
    setTotalSupply((await contract.totalSupply()).toNumber());
    setFreeMinted((await contract.FREE_MINTED()).toNumber());
    setBalance(await contract.balanceOf(userAddress));
    setClaimed(await contract.CLAIMED(userAddress));
  };

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  const mintTokens = async (amount, price) => {
    try {
      setLoading(true);
      setBtnDisabled(true);
      let transaction;
      if (isAllowlist) {
        const merkleProof = await allowlist.getProofForAddress(userAddress);
        transaction = await contract.allowlistMint(amount, merkleProof, {
          value: ethers.utils.parseUnits(price, "ether"),
        });
      } else {
        transaction = await contract.mint(amount, {
          value: ethers.utils.parseUnits(price, "ether"),
        });
      }
      setLoading(false);
      await transaction.wait();
      await refreshStateAfterMint();
    } catch (e) {
      console.log(e);
      alert(
        "An error occurred during the transaction. \n\nPlease check and ensure you have sufficient Gas and funds to complete this transaction.\n\nTry reconnecting or switching to a different wallet, then refresh the page to proceed with the transaction."
      );
    } finally {
      setBtnDisabled(false);
    }
  };

  return (
    <div>
      {loading ? <Loading /> : null}
      <div>
        <MintWidget
          userAddress={userAddress}
          salePrice={salePrice}
          plFree={plFree}
          alFree={alFree}
          maxTx={maxTx}
          maxSupply={maxSupply}
          freeMinted={freeMinted}
          totalSupply={totalSupply}
          isAllowlist={isAllowlist}
          claimed={claimed}
          isPaused={isPaused}
          balance={balance}
          loading={loading}
          isWalletConnected={active}
          disabled={btnDisabled}
          mintTokens={(mintAmount, price) => mintTokens(mintAmount, price)}
          connectWallet={() => connectWallet()}
          disconnectWallet={() => deactivate()}
        />
      </div>
    </div>
  );
};

export default MintTemplate;
