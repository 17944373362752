import React, { StrictMode } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import MintTemplate from "../components/Mint/MintTemplate";
import Menu from "../components/Menu/Menu";
import { Footer } from "../components";
import "../styles/mint.css";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};
function Mint() {
  return (
    <div className="container-mint">
      <Menu></Menu>
      <StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
          <MintTemplate />
        </Web3ReactProvider>
      </StrictMode>
      <Footer></Footer>
    </div>
  );
}

export default Mint;
