import { motion } from "framer-motion";
import { TypingText } from "../components";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";

const About = () => (
  <section className={`${styles.paddings} relative z-10`}>
    <div className="gradient-02 z-0" />
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto ${styles.flexCenter} flex-col`}
    >
      <TypingText title="| About Us" textStyles="text-center" />

      <motion.p
        variants={fadeIn("up", "tween", 0.2, 1)}
        className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-center text-green-300"
      >
        Welcome to the world of Monkey Madness, where 3072 animated zombie
        monkeys are ready to take over the world. Each monkey is specially drawn
        and has its own mix of looks, clothes, and accessories. The whole
        collection is inspired by the idea of overcoming challenges. They might
        be zombies or mutants, but they don't let their differences hold them
        back. Instead, they use their special powers to overcome obstacles and
        achieve their dreams.<br></br>
        <br></br> Monkey Madness is more than just a bunch of NFTs. It's a
        celebration of being different, never giving up, and being creative.
        Hope you like the vibes and the special of Monkey Madness Collection.
        <br></br> Welcome to{" "}
        <span className="font-extrabold text-green-100">
          Monkey Madness Collection
        </span>{" "}
      </motion.p>

      <motion.img
        variants={fadeIn("up", "tween", 0.3, 1)}
        src="/arrow-down.svg"
        alt="arrow down"
        className="w-[18px] h-[28px] object-contain mt-[28px]"
      />
    </motion.div>
  </section>
);

export default About;
