/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";
import { switchChain } from "../utils/switchChain";
import { getContractProvider } from "../utils/getContractProvider";
import config from "../config";
import ConnectMetamask from "./ConnectMetamask";
import "../styles/register.css";
import "../index.css";

const contractAbi = require("../contracts/abi.abi.json");

const StakeTemplate = () => {
  const { account, activate, deactivate, active, library } = useWeb3React();
  const [contract, setContract] = useState();
  const [userAddress, setUserAddress] = useState(null);
  const [walletOfOwner, setWalletOfOwner] = useState(null);
  const [isModalSelected, setIsModalSelected] = useState(false);
  const [code, setCode] = useState(null);

  useEffect(() => {
    const init = async () => {
      setContract(await getContractProvider());
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
  }, [account]);

  useEffect(() => {
    if (userAddress && contract) {
      const refresh = async () => {
        await refreshContractStateHasAddress();
      };
      refresh();
    }
  }, [userAddress, contract]);

  useEffect(() => {
    if (library) {
      setContract(
        new ethers.Contract(
          process.env.REACT_APP_ADDRESS,
          contractAbi,
          library.getSigner()
        )
      );
    }
  }, [library]);

  const refreshContractStateHasAddress = async () => {
    setWalletOfOwner(await contract.walletOfOwner(userAddress));
  };

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  const handleActive = () => {
    if (userAddress == null) {
      alert("Please connect the wallet on the right side of the screen!");
    } else if (walletOfOwner?.length > 0) {
      const param = {
        wallet: userAddress,
        quantity: Number(walletOfOwner.length),
      };

      fetch(`${process.env.REACT_APP_END_POINT}/api/v1/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === false) {
            alert(data.message);
          } else {
            console.log(data.data);
            setCode(data.data.code);
            setIsModalSelected(true);
          }
        })
        .catch((error) => {
          alert("ERR! " + error);
        });
    } else {
      alert(
        "YOU ARE NOT HOLDER YET, You Have To Own At Least 1 NFTs To Register"
      );
    }
  };

  const closeModal = () => {
    setIsModalSelected(false);
    setCode(null);
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_END_POINT}/api/v1/get-register`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === false) {
          alert(data.message);
        } else {
          setData(data.data);
        }
      })
      .catch((error) => {
        alert("ERR! " + error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="register-part">
      <div className="connect">
        <ConnectMetamask
          userAddress={userAddress}
          isWalletConnected={active}
          connectWallet={() => connectWallet()}
          disconnectWallet={() => deactivate()}
        />
      </div>
      <div className="part-one">
        <p className="mt-[8px] font-normal sm:text-[32px] text-[20px] text-green-300">
          Welcome early holders of Monkey Madness. <br />
          We will start by hosting regular GA events to benefit Monkey Madness
          Holders
          <br />
          <br />
          <b>REWARDS: 1000$ ETH</b>
          <br />
          <br />
          RULES:
          <br />
          1 NFT = 1 Ticket
          <br />
          The more NFT you have, the higher chance you can win the money
          <br />
          Note: The snapshot and raffle will happen after sold out
          <br />
          Be Quick, Take That Monkey
        </p>
        <button onClick={() => handleActive()}>Register</button>
      </div>

      <div className="part-two">
        <div className="rank-rank">
          <h2>Registered</h2>
          <table className=" border-collapse border-white border-2 text-white">
            <thead>
              <tr>
                <th className="p-2 uppercase border-white border-2   text-36 my-14 font-extrabold ">
                  No.
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  WALLET
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  QUANTITY
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  CODE
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr>
                    <td className="p-2 text-black-700 uppercase border-white border-2  text-36 my-14 font-extrabold px-10">
                      {index + 1}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-10">
                      {(() => {
                        const new_str =
                          item.wallet.substring(0, 6) +
                          "...." +
                          item.wallet.substring(item.wallet.length - 6);
                        return new_str;
                      })()}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-7">
                      {item.quantity}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-7 text-blue-300 ">
                      <b>
                        {item.stype === 0 ? "B" + item.code : "A" + item.code}
                      </b>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalSelected && (
        <div className="modal-overlay ">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Registered!</h2>
              <button onClick={closeModal}>❌</button>
            </div>
            <div className="modal-body">
              <p>You have successfully registered</p>
              <p>
                Your code: <b className="text-blue-400">{code}</b>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakeTemplate;
