import React, { useState, useEffect, StrictMode } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Template from "../components/Template";
import "../App.css";
import "../global.css";
import Card from "../components/Card/Card.js";
import Menu from "../components/Menu/Menu";
import Rank from "../components/Rank/Rank.js";
import { Footer } from "../components";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};

const initialCards = [
  { src: "/images/card/11.gif", matched: false },
  { src: "/images/card/12.gif", matched: false },
  { src: "/images/card/13.gif", matched: false },
  { src: "/images/card/14.gif", matched: false },
  { src: "/images/card/15.gif", matched: false },
  { src: "/images/card/16.gif", matched: false },
  { src: "/images/card/17.gif", matched: false },
  { src: "/images/card/18.gif", matched: false },
  { src: "/images/card/19.gif", matched: false },
  { src: "/images/card/20.gif", matched: false },
];

function Game() {
  const pause = true;
  const [cards, setCards] = useState([]);
  const [turn, setTurn] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [startFlip, setStartFlip] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [time, setTime] = useState(0);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [isGameComplete, setIsGameComplete] = useState(false);
  const [twitter, setTwitter] = useState(null);

  const [userAddress, setUserAddress] = useState(null);

  function startNewGame() {
    if (pause) {
      alert(
        "Sorry, we are temporarily pausing the game to give out prizes. Please come back to the game later!"
      );
    } else {
      if (userAddress == null) {
        alert("Please connect wallet in the right corner of the screen!");
      } else if (twitter == null) {
        alert("Please enter your twitter!");
      } else {
        setIsGameRunning(true);
        setIsGameComplete(false);
        setTime(0);
        shuffleCards();
      }
    }
  }

  useEffect(() => {
    let timer;
    if (isGameRunning && !isGameComplete) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isGameRunning, isGameComplete]);

  useEffect(() => {
    const allMatched = cards.every((card) => card.matched);
    if (allMatched) {
      setIsGameComplete(true);
      setIsGameRunning(false);
    }
  }, [cards]);

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => {
          resetTurn();
        }, 1000);
      }
    }

    const allMatched = cards.every((card) => card.matched);

    if (allMatched) {
      setGameWon(true);
    }
  }, [choiceOne, choiceTwo, cards]);

  useEffect(() => {
    setTimeout(() => {
      setStartFlip(false);
    }, 1000);
    shuffleCards();
  }, []);

  function shuffleCards() {
    setGameWon(false);
    const shuffledCards = [...initialCards, ...initialCards]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));

    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setTurn(0);
    setDisabled(false);
    setStartFlip(false);
  }

  function handleChoice(card) {
    choiceOne
      ? choiceOne.id !== card.id && setChoiceTwo(card)
      : setChoiceOne(card);
  }

  function resetTurn() {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurn((prevTurn) => prevTurn + 1);
    setDisabled(false);
  }

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });
        resetTurn();
      } else {
        setTimeout(() => {
          resetTurn();
        }, 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  const handleChange = (event) => {
    setTwitter(event.target.value);
  };

  // win game

  useEffect(() => {
    if (gameWon && isGameComplete) {
      console.log(twitter, time, turn);
      const param = {
        wallet: userAddress,
        turn,
        time,
        twitter,
      };

      fetch(
        `${process.env.REACT_APP_END_POINT}/api/v1/monkey-madness/send-score`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(param),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === false) {
            alert(data.message);
          } else {
            console.log(data.data);
          }
        })
        .catch((error) => {
          alert("ERR! " + error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameWon, isGameComplete]);

  useEffect(() => {
    if (userAddress) {
      fetch(
        `${process.env.REACT_APP_END_POINT}/api/v1/monkey-madness/get-twitter/${userAddress}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === false) {
            alert(data.message);
          } else {
            console.log();
            setTwitter(data.data.twitter);
          }
        })
        .catch((error) => {
          alert("ERR! " + error);
        });
    }
  }, [userAddress]);

  const closeModal = () => {
    setGameWon(false);
    setIsGameRunning(false);
    setIsGameComplete(false);
    setTime(0);
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurn(0);
    setDisabled(false);
    setStartFlip(false);
  };

  return (
    <div className="bg-container">
      <Menu></Menu>
      <div className="connect">
        <StrictMode>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Template
              userAddress={userAddress}
              setUserAddress={setUserAddress}
            />
          </Web3ReactProvider>
        </StrictMode>
      </div>
      <div className="containerGame">
        <div className="left-column">
          <div>
            <label>Twitter: </label>
            <input
              type="text"
              placeholder="@MonkeyMadnessfi"
              value={twitter}
              onChange={handleChange}
            ></input>
          </div>
          <br />
          <button
            onClick={startNewGame}
            disabled={isGameRunning}
            style={{
              animation: isGameRunning ? "none" : "scaleButton 1s infinite",
            }}
          >
            {isGameRunning ? "Playing..." : "Start Game"}
          </button>
        </div>

        <div className="gridGame">
          {cards.map((card) => (
            <Card
              key={card.id}
              card={card}
              handleChoice={handleChoice}
              flipped={
                card === choiceOne ||
                card === choiceTwo ||
                card.matched ||
                startFlip
              }
              disabled={disabled}
              matched={card.matched}
              isGameRunning={isGameRunning}
              isGameComplete={isGameComplete}
            />
          ))}
        </div>
        <div className="right-column">
          <p>Turns: {turn}</p>
          <p>Time: {time}s</p>
        </div>
      </div>
      {gameWon && (
        <div className="modal-overlay ">
          <div className="modal-content selected">
            <div className="modal-header">
              <h2> Congratulations!</h2>
            </div>
            <div className="modal-body">
              <p>{twitter}!</p>
              <h1>You've won the game.</h1>
              <div className="info-score">
                <p>Turns: {turn}</p>
                <p>Time: {time}s</p>
              </div>
              <i>(We have stored your achievements)</i>
              <button className="modal-close" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      <Rank></Rank>

      <Footer></Footer>
    </div>
  );
}

export default Game;
