/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";
import { switchChain } from "../utils/switchChain";
import config from "../config";
import "../index.css";
import ConnectMetamask from "./ConnectMetamask";

function Template({ userAddress, setUserAddress }) {
  const { account, activate, deactivate, active } = useWeb3React();

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
  }, [account]);

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  return (
    <div>
      <ConnectMetamask
        userAddress={userAddress}
        isWalletConnected={active}
        connectWallet={() => connectWallet()}
        disconnectWallet={() => deactivate()}
      />
    </div>
  );
}

export default Template;
