import React from "react";
import "../styles/notfound.css";

function NotFound() {
  return (
    <div className="not-found-container">
      <h1>404</h1>
      <h2>Not Found</h2>
    </div>
  );
}

export default NotFound;
