import React, { useState } from "react";
import styles from "./menu.module.css";

function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className={styles.app}>
        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <a href="/" className={styles.navLink}>
                Home
              </a>
            </li>

            <li className={styles.navItem}>
              <a href="/game" className={styles.navLink}>
                Game
              </a>
            </li>

            {/* <li className={styles.navItem}>
              <a href="/walletchecker" className={styles.navLink}>
                WLChecker
              </a>
            </li> */}

            <li className={styles.navItem}>
              <a href="/free-mint" className={styles.navLink}>
                Mint
              </a>
            </li>
          </ul>
        </nav>
      </div>

      {/* mobile */}
      <div className={styles.m_app}>
        <button className={styles.m_menuToggle} onClick={toggleMenu}>
          ☰ MENU
        </button>
        <nav className={`${styles.m_nav} ${isMenuOpen ? styles.open : ""}`}>
          <ul className={styles.m_navList}>
            <li className={styles.m_navItem}>
              <a href="/" className={styles.m_navLink}>
                Home
              </a>
            </li>
            <li className={styles.m_navItem}>
              <a href="/game" className={styles.m_navLink}>
                Game
              </a>
            </li>
            {/* <li className={styles.m_navItem}>
              <a href="/walletchecker" className={styles.m_navLink}>
                WLChecker
              </a>
            </li> */}
            <li className={styles.m_navItem}>
              <a href="/free-mint" className={styles.m_navLink}>
                Mint
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Menu;
