import React, { StrictMode } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import StakeTemplate from "./StakeTemplate";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};

const Register = () => {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <StakeTemplate />
      </Web3ReactProvider>
    </StrictMode>
  );
};
export default Register;
