import React from "react";
import { formatAddress } from "../utils/helpers";

const ConnectMetamask = (props) => {
  return (
    <>
      <button
        type="button"
        className="px-10 w-full text-black  rounded-xl py-2 text-2xl uppercase  disabled:bg-[#B7B7B7] disabled:text-[#090909] disabled:hover:cursor-not-allowed"
        onClick={
          !props.isWalletConnected
            ? props.connectWallet
            : props.disconnectWallet
        }
      >
        <span className="connect-blacklist flex items-center justify-center ">
          {!props.isWalletConnected ? (
            <span>Connect Wallet</span>
          ) : (
            <>
              <span className="mr-2">
                {props.userAddress && formatAddress(props.userAddress, 4)}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <line
                  x1="200"
                  y1="56"
                  x2="56"
                  y2="200"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
                <line
                  x1="200"
                  y1="200"
                  x2="56"
                  y2="56"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></line>
              </svg>
            </>
          )}
        </span>
      </button>
    </>
  );
};

export default ConnectMetamask;
