import { motion } from "framer-motion";
import { TitleText } from "../components";

import styles from "../styles";
import { fadeIn, staggerContainer } from "../utils/motion";

const Feedback = () => (
  <section className={`${styles.paddings}`}>
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-6`}
    >
      {/* Card for Instagram Follow */}

      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className="flex-[0.95] flex justify-center flex-col"
      >
        <div className="mt-5">
          {" "}
          <TitleText title={<>Stop and Follow Me on Twitter!</>} />
        </div>
      </motion.div>

      <motion.div
        variants={fadeIn("right", "tween", 0.2, 1)}
        className="relative flex-1 flex justify-center items-center"
      >
        <a
          href={`https://twitter.com/MonkeyMadnessfi`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <motion.img
            src="/images/twitter.png"
            alt="twitter Icon"
            className="w-60 h-60 full cursor-pointer"
            whileHover={{ rotate: 5, scale: 1.1 }}
          />
        </a>
      </motion.div>
    </motion.div>
  </section>
);

export default Feedback;
