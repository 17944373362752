import React from "react";
import { utils } from "ethers";
import ConnectMetamask from "../ConnectMetamask";
import "../../styles/mint.css";

const defaultState = {
  mintAmount: 1,
};

export default class MintWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  incrementMintAmount() {
    if (this.props.maxTx > this.props.balance.toNumber())
      this.setState({
        mintAmount: Math.min(
          this.props.maxTx - this.props.balance.toNumber(),
          this.state.mintAmount + 1
        ),
      });
  }

  decrementMintAmount() {
    this.setState({
      mintAmount: Math.max(1, this.state.mintAmount - 1),
    });
  }

  getPrice(mintAmount) {
    let amount = mintAmount;
    if (this.props.maxTx === this.props.balance.toNumber()) amount = 1;
    let maxFree = this.props.plFree;
    if (this.props.isAllowlist) {
      maxFree = this.props.alFree;
    }

    if (!(this.props.claimed >= maxFree)) {
      if (mintAmount <= maxFree - this.props.claimed) {
        amount = 0;
      } else {
        amount = mintAmount - (maxFree - this.props.claimed);
      }
    }

    return utils.formatEther(this.props.salePrice.mul(amount));
  }

  async mint(amount = this.state.mintAmount) {
    if (amount !== this.state.mintAmount) {
      this.setState({ mintAmount: amount });
    }
    if (!this.props.isPaused) {
      let price = this.getPrice(amount);
      await this.props.mintTokens(amount, price);
      return;
    }
  }

  isMaxTx(quantity) {
    return this.props.balance.toNumber() + quantity > this.props.maxTx;
  }

  isSoldOut() {
    return (
      this.props.maxSupply !== 0 &&
      this.props.totalSupply >= this.props.maxSupply
    );
  }

  textButtonMint() {
    if (
      (this.props.isWalletConnected || !this.props.loading) &&
      !this.props.isPaused
    ) {
      if (this.isSoldOut()) {
        return "Sold Out";
      } else {
        if (this.props.isAllowlist) {
          return "Allowlis Mint";
        }
        return "Mint";
      }
    }
    return "Not open yet";
  }

  render() {
    return (
      <>
        <div>
          <div className="header">
            <h1 className="font-bold">Baby Monkey</h1>
            <div className="header__social">
              <a
                href="https://opensea.io/collection/babymonkeyofficial"
                target="_blank"
                className="social-item social-item_eser"
                rel="noreferrer"
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="121.000000pt"
                  height="121.000000pt"
                  viewBox="0 0 121.000000 121.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                  >
                    <path
                      d="M480 1184 c-266 -57 -470 -311 -470 -584 0 -324 276 -600 600 -600
276 0 528 205 585 475 43 204 -16 397 -164 546 -149 149 -347 207 -551 163z
m180 -269 c4 -32 15 -49 57 -88 51 -48 103 -133 103 -169 0 -25 -25 -74 -62
-120 -21 -26 -38 -38 -61 -40 -28 -3 -32 -7 -35 -35 -3 -33 -3 -33 42 -33 36
0 52 6 80 30 22 20 64 39 118 55 102 30 98 30 98 0 0 -17 -9 -29 -30 -40 -16
-8 -50 -46 -75 -84 -64 -97 -75 -101 -277 -101 -199 0 -249 11 -301 69 -36 40
-63 111 -53 137 4 10 27 14 91 14 l86 0 11 -30 c13 -38 35 -50 92 -50 46 0 46
0 46 35 0 35 0 35 -45 35 l-45 0 25 40 c37 59 47 89 46 145 0 50 -24 134 -56
195 l-17 33 29 -7 c15 -4 36 -9 46 -12 13 -4 17 0 17 18 0 30 19 50 45 46 15
-2 21 -13 25 -43z m-164 -137 c23 -70 23 -135 -1 -176 l-19 -32 -89 0 c-70 0
-86 3 -80 13 58 96 158 247 164 247 4 0 15 -24 25 -52z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                href="https://etherscan.io/address/0xBBFD3010490372D48DfED0350d0583B43b04BB52"
                target="_blank"
                className="social-item social-item_open"
                rel="noreferrer"
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="122.000000pt"
                  height="122.000000pt"
                  viewBox="0 0 122.000000 122.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,122.000000) scale(0.100000,-0.100000)"
                    stroke="none"
                  >
                    <path
                      d="M550 1214 c-157 -23 -273 -77 -367 -172 -176 -176 -229 -428 -137
                    -657 37 -91 53 -105 122 -105 32 0 63 5 70 12 8 8 12 64 12 186 0 100 4 181
                    10 193 9 16 22 19 83 19 44 0 78 -5 85 -12 8 -8 12 -64 12 -186 l0 -173 23 7
                    c47 14 47 15 47 245 0 256 -3 249 94 249 96 0 96 1 96 -227 0 -187 0 -191 20
                    -186 46 12 50 32 50 283 0 170 3 239 12 248 19 19 143 16 159 -5 10 -12 15
                    -65 19 -204 l5 -188 68 57 c95 80 147 146 147 186 0 42 -47 135 -105 209 -121
                    153 -344 247 -525 221z"
                    ></path>
                    <path
                      d="M1181 615 c-40 -59 -187 -193 -275 -250 -153 -100 -337 -174 -539
                    -216 -121 -25 -123 -31 -30 -75 85 -40 140 -54 238 -60 174 -12 331 50 462
                    182 111 112 180 275 171 406 l-3 48 -24 -35z"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
            <div className="connect-mint">
              <ConnectMetamask
                userAddress={this.props.userAddress}
                isWalletConnected={this.props.isWalletConnected}
                connectWallet={() => this.props.connectWallet()}
                disconnectWallet={() => this.props.disconnectWallet()}
              />
            </div>
          </div>
          <div className="mint-part font-[Chakra_Petch]">
            <div className=" px-0 pt-4 w-full relative text-white">
              <p className="content">
                Baby Monkey Is The Fully Animated NFT Collection That Bringing
                The Quality Arts To NFT Space
                <br />
                <br />
                The First Quality Fully Animated NFT That Partly Free Mint To
                Holders
                <br />
                We Are Not Just NFT, We Are The Milestone
                <br />
              </p>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center justify-center flex-col space-x-2">
                  <p className="text-7xl textCheck font-bold mt-4">
                    {this.props.totalSupply}/{this.props.maxSupply}
                  </p>
                </div>
                <div className="flex flex-col gap-1 sm:gap-4 mx-auto">
                  <div className="flex w-full items-center justify-between mx-auto">
                    <button
                      type="button"
                      className="h-10 w-10 border-2 border-white hover:bg-black hover:bg-opacity-20 rounded"
                      onClick={() => this.decrementMintAmount()}
                    >
                      <div className="flex items-center justify-center">
                        <span className="text-4xl text-white -mt-1">-</span>
                      </div>
                    </button>
                    <div className="h-10 w-20 px-6 flex items-center justify-center ">
                      <div className="font-bold text-3xl text-white">
                        {this.state.mintAmount}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="h-10 w-10 border-2 border-white hover:bg-black hover:bg-opacity-20 rounded"
                      onClick={() => this.incrementMintAmount()}
                    >
                      <div className="flex items-center justify-center">
                        <span className="text-4xl text-white -mt-1">+</span>
                      </div>
                    </button>
                  </div>
                  {this.props.isWalletConnected ? (
                    <p className="text-center text-xl">
                      Total: {this.getPrice(this.state.mintAmount)} ETH
                    </p>
                  ) : null}
                </div>

                <div className="button-mint grid grid-cols-1 max-w-xs mx-auto justify-between items-center gap-5 pt-8">
                  <button
                    className="w-full text-white bg-gray-600 hover:bg-gray-700 border-none hover:shadow-[3px_3px_8px_0_rgba(91,91,91,1)] rounded-xl py-2 font-semibold text-2xl uppercase hover:text-white disabled:bg-[#B7B7B7] disabled:text-[#090909] disabled:hover:cursor-not-allowed"
                    disabled={
                      !this.props.isWalletConnected ||
                      this.props.isPaused ||
                      this.props.loading ||
                      this.props.disabled ||
                      this.isSoldOut() ||
                      this.isMaxTx(this.state.mintAmount)
                    }
                    onClick={() => this.mint()}
                  >
                    <span className="flex items-center relative h-full w-full opacity-100 justify-center text-white">
                      <span className="flex items-center px-2">
                        {this.textButtonMint()}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mint-img">
              <img
                src="https://ipfs.io/ipfs/QmSfgiYxttfjSNZ7hMfLxd82hWHxmjF76YETXrq6tg7Qym/10-1.gif"
                alt="gif"
              ></img>
            </div>
          </div>
        </div>
      </>
    );
  }
}
