import "./Card.css";

function Card(props) {
  const isGameRunning = props.isGameRunning;
  const isGameComplete = props.isGameComplete;

  function handleChoice() {
    if (!isGameRunning || isGameComplete || props.disabled) return;
    props.handleChoice(props.card);
  }
  return (
    <div className="card">
      <div className={props.flipped ? "flipped" : ""}>
        <img
          className={`front ${props.card.matched ? "matched" : ""}`}
          src={
            props.flipped || props.card.matched
              ? props.card.src
              : "/images/card_back.png"
          }
          alt="card front"
        />
        {props.flipped || props.card.matched ? null : (
          <img
            className="back"
            src="/images/card_back.png"
            alt="card back"
            onClick={() => handleChoice()}
          />
        )}
      </div>
    </div>
  );
}

export default Card;
