import React, { useState, useEffect } from "react";
import "./rank.css";

function Rank() {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_END_POINT}/api/v1/monkey-madness/get-rank`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === false) {
          alert(data.message);
        } else {
          setData(data.data);
        }
      })
      .catch((error) => {
        alert("ERR! " + error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="rank-container">
      <div className="rank-desc">
        <h2>Description & Guidance</h2>
        <div>
          Step into the captivating world of Monkey Madness, where animated NFTs
          symbolize the indomitable spirit of overcoming challenges and
          embracing diversity. Embark on a quest of memory and wit as you
          navigate this engaging game of matching pairs.
          <br />
          <br />
          Your mission is to uncover and match identical pairs of Monkey Madness
          NFTs among the ten sets presented. The fewer flips and the shorter
          your playing time, the higher your ranking and potential rewards will
          be.
          <br />
          <br />
          Each week, scores and rankings are reset, and enticing prizes await
          the top players
          <br />
          <br />
          • 1st Place: $50 + 1 Monkey Madness NFT
          <br />• 2nd Place: $30 + 1 Monkey Madness NFT <br />• 3rd Place: $20 +
          1 Monkey Madness NFT <br />• 10 Consolation Prizes: 1 Monkey Madness
          NFT Each <br />• 20 Additional Prizes: Whitelist Spots
          <br />
          <br />
          <i>
            Note: All acts of cheating are prohibited and that results will not
            be valid, no excuses.
          </i>
        </div>
      </div>
      <div>
        <div className="rank-rank">
          <h2>Ranking</h2>
          <table className=" border-collapse border-white border-2 text-white">
            <thead>
              <tr>
                <th className="p-2 uppercase border-white border-2   text-36 my-14 font-extrabold ">
                  RANK
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  WALLET
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  TURN
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold">
                  TIME
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  TIMES
                </th>
                <th className="p-2 uppercase border-white border-2  text-36 my-14 font-extrabold ">
                  TWITTER
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr>
                    <td className="p-2 text-black-700 uppercase border-white border-2  text-36 my-14 font-extrabold px-10">
                      {index + 1}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-10">
                      {(() => {
                        const new_str =
                          item.wallet.substring(0, 6) +
                          "...." +
                          item.wallet.substring(item.wallet.length - 6);
                        return new_str;
                      })()}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-7">
                      {item.turn}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-7">
                      {item.time}s
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  px-7">
                      {item.time_plays}
                    </td>
                    <td className="p-2 text-black-700 border-white border-2  text-blue-400 visited:text-purple-600 underline underline-offset-1 px-7">
                      <a
                        href={`https://twitter.com/${item.twitter}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.twitter}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default Rank;
