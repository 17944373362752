import { useState } from "react";
import { motion } from "framer-motion";
import styles from "../styles";
import { TitleText, TypingText } from "../components";
import { planetVariants, staggerContainer, fadeIn } from "../utils/motion";

const WhatsNew = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const openModal1 = () => {
    setShowModal1(true);
  };

  const openModal2 = () => {
    setShowModal2(true);
  };

  const openModal3 = () => {
    setShowModal3(true);
  };

  const openModal4 = () => {
    setShowModal4(true);
  };

  const closeModal = () => {
    setShowModal1(false);
    setShowModal2(false);
    setShowModal3(false);
    setShowModal4(false);
  };

  return (
    <section className={`${styles.paddings} relative z-10`}>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false, amount: 0.25 }}
        className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
      >
        <motion.div
          variants={planetVariants("left")}
          className={`flex-1 ${styles.flexCenter}`}
        >
          <img
            src="/images/road_map.webp"
            alt="get-started"
            className="w-[100%] h-[90%] object-contain"
          />
        </motion.div>
        <motion.div
          variants={fadeIn("left", "tween", 0.2, 1)}
          className="flex-[0.95] flex justify-center flex-col"
        >
          <div className="mt-5">
            {" "}
            {/* Added padding to create distance */}
            <TypingText title="| Have Fun Together!" />
            <TitleText title={<>What Is Monkey Madness Road Map?</>} />
          </div>
          <div className="mt-6">
            <p className="text-lg text-center text-emerald-300">
              <b
                className="text-white bg-emerald-300 cursor-pointer"
                onClick={openModal1}
              >
                Phase 1: Pre-Launch
              </b>{" "}
              <br />
              ☸Concept development and marketing campaign <br />
              <b
                className="text-white bg-emerald-300 cursor-pointer"
                onClick={openModal2}
              >
                Phase 2: Official Launch
              </b>{" "}
              <br />
              ☸Sale of 3072 animated NFTs to Whitelist <br />
              <b
                className="text-white bg-emerald-300 cursor-pointer"
                onClick={openModal3}
              >
                Phase 3: SocialFi & NFT Game
              </b>
              <br />
              ☸Development of SocialFi platform (Engage to Earn) and NFT Game
              (Play to Earn) <br />
              <b
                className="text-white bg-emerald-300 cursor-pointer"
                onClick={openModal4}
              >
                Phase 4: Tokenomics & Ecosystem
              </b>
              <br />
              ☸Development of Monkey Madness ecosystem and application of NFTs
              through (Stake to Earn)
            </p>
          </div>
        </motion.div>
      </motion.div>
      {showModal1 && (
        <div className="modal-overlay">
          <div className="modal-content selected">
            <div className="modal-header">
              <h2> Phase 1: Pre-launch</h2>
            </div>
            <div className="home-modal modal-body">
              <p>
                * Complete art concept, design and hand-draw all graphics
                <br />
                * Create animations and details for each NFT
                <br />
                * Launch marketing campaigns & Collaborate with web3 communities
                <br />
                * Launch Game For whitelist and Early Rewards
                <br />
                <br />
              </p>

              <button className="modal-close" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal2 && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2> Phase 2: Launch</h2>
            </div>
            <div className="home-modal modal-body">
              <p>
                * Launch collection of 3072 NFTs <br />
                <p className="ml-[40px]">
                  - 73 NFTs will be used as rewards for game and socialFi
                  <br></br>- 2999 NFTs will be open at a discounted price to
                  Whitelist
                  <br></br> - The remaining NFTs after WhiteList will be
                  distributed to the public<br></br>
                </p>
              </p>
              <p>
                {" "}
                * All NFTs from the Monkey Madness collection are animated (25
                fps)
                <br />* Each NFT is unique and hand-drawn with IPR belong to the
                NFT holders
              </p>
              <br></br>

              <button className="modal-close" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal3 && (
        <div className="modal-overlay">
          <div className="modal-content selected">
            <div className="modal-header">
              <h2>Phase 3: Socialfi and NFT Game</h2>
            </div>
            <div className="home-modal modal-body">
              <p>
                * Open Socialfi (Engage To Earn) features on the project website
                <br />
                * Open NFT game (Play To Earn) with rewards system for holders
                <br />
                * Hold Mini Game & Contest related to arts and NFTs
                <br />
                * Rewards earned from SociaFi & Game, can be redeemed for NFTs,
                ETH,..)
                <br />
              </p>

              <button className="modal-close" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal4 && (
        <div className="modal-overlay">
          <div className="modal-content selected">
            <div className="modal-header">
              <h2>Phase 4: Tokenomic + Ecosystem</h2>
            </div>
            <div className="home-modal modal-body">
              <p>
                * Launching Tokenomic System
                <br />
                * Airdrop Token To Monkey Madness Holders
                <br />
                * Develop Ecosystem of Monkey Madness
                <br />
                * Building the base through staking system ( Stake To Earn)
                <br />
              </p>

              <button className="modal-close" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default WhatsNew;
