import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import Home from "./pages/Home";
import Game from "./pages/Game";
import Mint from "./pages/Mint";
import NotFound from "./pages/NotFound";
import WalletChecker from "./pages/WalletChecker";

window.Buffer = window.Buffer || Buffer;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game" element={<Game />} />
        <Route path="/walletchecker" element={<WalletChecker />} />
        <Route path="/free-mint" element={<Mint />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
