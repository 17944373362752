import React, { useState } from "react";
import styles from "../styles/walletCheck.module.css";
import Loading from "../components/Loading";
import axios from "axios";
import Menu from "../components/Menu/Menu";
import "../global.css";

const WalletChecker = () => {
  const [wallet, setWallet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalSelected, setIsModalSelected] = useState(false);
  const [isModalNotSelected, setIsModalNotSelected] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsModalSelected(false);
    setIsModalNotSelected(false);
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("wallet", wallet);
      axios
        .post(
          `${process.env.REACT_APP_END_POINT}/api/v1/check-wallet`,
          formData
        )
        .then((response) => {
          //   console.log(response.data.data);
          setIsLoading(false);
          if (response.data.status === true) {
            setIsModalSelected(true);
          } else {
            setIsModalNotSelected(true);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const closeModal = () => {
    setIsModalSelected(false);
  };

  const closeModalNot = () => {
    setIsModalNotSelected(false);
  };
  return (
    <div className={styles.containerW}>
      {isLoading && <Loading></Loading>}
      <Menu></Menu>
      <div className={styles.WalletChecker}>
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalBody}>
              <form onSubmit={handleSubmit}>
                <div>
                  <label >
                    <p>
                      Welcome Here
                      <br></br>
                      Don't Be Late, Check WL Now
                      <br />
                      We Are About To Mint Soon
                      <br />
                      <br />
                      Only White List Are Eligible For Special Mint Phase
                    </p>
                  </label>
                  <div className={styles.checkPart}>
                    <input
                      type="text"
                      value={wallet}
                      onChange={(e) => setWallet(e.target.value)}
                      placeholder="0x..."
                      required
                    />
                    <button type="submit">Check 👈</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isModalSelected && (
        <div className="modal-overlay ">
          <div className="modal-content">
            <div className="modal-header">
              <h2> Whitelisted!</h2>
              <button onClick={closeModal}>❌</button>
            </div>
            <div className="modal-body">
              <img src="/images/isWL.png" alt="happy"></img>
              <p className="text-center">
                Congrats, You Are Whitelisted, You Are Eligible For Monkey
                Madness
              </p>
            </div>
          </div>
        </div>
      )}

      {isModalNotSelected && (
        <div className="modal-overlay ">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Not Yet</h2>
              <button onClick={closeModalNot}>❌</button>
            </div>
            <div className="modal-body">
              <img src="/images/notWL.png" alt="sad"></img>
              <p className="text-center">
                Sorry, You Are Not Whitelisted Yet. Take Your Last Chance WL on
                the pin Tweet
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default WalletChecker;
