export const exploreWorlds = [
  {
    id: "world-1",
    imgUrl: "images/w1.webp",
    title: "W 1",
  },
  {
    id: "world-2",
    imgUrl: "images/w2.webp",
    title: "W 2",
  },
  {
    id: "world-3",
    imgUrl: "images/w3.webp",
    title: "W 3",
  },
  {
    id: "world-4",
    imgUrl: "images/w4.webp",
    title: "W 4",
  },
  {
    id: "world-5",
    imgUrl: "images/w5.webp",
    title: "W 5",
  },
];

export const socials = [
  {
    name: "discord",
    iconUrl: "/images/discord.png",
    url: "https://discord.com/invite/eRJf42DHMW",
  },
  {
    name: "twitter",
    iconUrl: "/images/twitter.png",
    url: "https://twitter.com/MonkeyMadnessfi",
  },
];
